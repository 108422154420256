#components-layout-demo-side .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }

  .header-content{
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  .logo {
    height: 32px;
    margin: 0px 0px 0px 8px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 18px;
  }

  .header-menu {
    font-size: 20px;
    cursor: pointer;
  }
